import { useState, useEffect, useMemo } from "react";

import {
  Grid,
  Card,
  Button,
  Stack,
  Typography,
  CircularProgress,
} from "@pankod/refine-mui";

import CustomDataGrid from "components/CustomDataGrid";

import axios from "utils/axios";
import { useQuery } from "react-query";
import { normalizeData } from "utils/utils";
import moment from "moment";
import { useNavigate } from "@pankod/refine-react-router-v6";

// custom add days method
Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export default function Dashboard() {
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [ongoing, setOngoing] = useState([]);
  const [upcoming, setUpcoming] = useState([]);
  const [past, setPast] = useState([]);

  const { data: allTrips, status } = useQuery("trips", async () => {
    let { data: res } = await axios.get("/api/trips");
    return res;
  });

  useEffect(() => {
    const fetchUser = async () => {
      const { data } = await axios.get("/api/users/me");
      setUser(data);
    };
    fetchUser();
  }, []);

  useEffect(() => {
    if (allTrips) {
      const ongoing = allTrips.filter(
        (trip) =>
          moment(trip.departure_date).isSameOrBefore(moment()) &&
          moment(trip.departure_date)
            .add(trip.trip_duration_days, "days")
            .isAfter(moment())
      );
      const upcoming = allTrips.filter((trip) =>
        moment(trip.departure_date).isAfter(moment())
      );
      const past = allTrips.filter((trip) =>
        moment(trip.departure_date)
          .add(trip.trip_duration_days, "days")
          .isBefore(moment())
      );
      setOngoing({ list: ongoing, status: "In Corso" });
      setUpcoming({ list: upcoming, status: "In Partenza" });
      setPast({ list: past, status: "Passati" });
    }
  }, [allTrips]);

  const columns = useMemo(
    () => [
      { field: "title", headerName: "Nome Viaggio", flex: 1, minWidth: 200 },
      {
        field: "destination",
        headerName: "Destinazione",
        flex: 1,
        minWidth: 200,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              {params.row?.destination?.title}
            </Stack>
          );
        },
      },
      {
        field: "trip_type",
        headerName: "Tipo Viaggio",
        flex: 1,
        minWidth: 200,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              {params.row?.trip_type?.title}
            </Stack>
          );
        },
      },
      {
        field: "package_type",
        headerName: "Tipo Pacchetto",
        flex: 1,
        minWidth: 200,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              {params.row?.package_type?.title}
            </Stack>
          );
        },
      },
      {
        headerName: "Actions",
        field: "actions",
        minWidth: 150,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <Button
                color="success"
                size="small"
                onClick={() => {
                  navigate(`/trip-view/${params.row.id}`);
                }}
              >
                Dettagli
              </Button>
            </Stack>
          );
        },
      },
    ],
    []
  );

  return (
    <Grid container spacing={5} justifyContent="space-evenly">
      <Grid item md={5} xs={11} sx={{ mt: -7, mb: 3 }}>
        <Card sx={{ borderRadius: 10, height: "100%" }}>
          <Grid
            item
            xs={12}
            container
            alignItems="space-between"
            p={3}
            sx={{ height: "100%" }}
          >
            <Grid item xs={12} container justifyContent="center">
              {user && (
                <h1 style={{ paddingTop: 10 }}>{`Ciao ${user.username}`}</h1>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>

      {[ongoing, upcoming, past].map((trips, index) => (
        <Grid item xs={12} container justifyContent="center" key={index}>
          <>
            {status != "loading" && trips.list && (
              <CustomDataGrid
                title={"Viaggi " + trips.status}
                data={trips.list}
                loading={status == "loading"}
                columns={columns}
              />
            )}
            {status == "loading" && <CircularProgress />}
          </>
        </Grid>
      ))}
    </Grid>
  );
}
