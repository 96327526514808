import { useState, useEffect } from "react";

import { Box, Input, Typography, Grid, Card } from "@pankod/refine-mui";
import { LoadingButton } from "@mui/lab";

import FileUploadIcon from "@mui/icons-material/FileUpload";

import axios from "utils/axios";
import { API_URL } from "constants";

export default function UploadZone({
  register,
  errors,
  setError,
  setValue,
  disabled,
  onChange,
  defaultValue,
  required,
  fieldName,
  isText,
  isButton,
  label,
  limit,
  width,
  height,
}) {
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    if (defaultValue && (defaultValue.length == 0 || defaultValue == "")) {
      if (setValue) setValue(fieldName, null);
      if (onChange) onChange(null);
    }
  }, [defaultValue]);

  const onChangeHandler = async (event) => {
    try {
      setIsUploadLoading(true);

      const formData = new FormData();

      const target = event.target;
      const file = target.files[0];
      const { size, type } = file;

      formData.append("files", file);
      if (fieldName) formData.append("field", fieldName);

      if (type !== "image/jpeg" && type !== "image/png") {
        setError(fieldName, {
          type: "manual",
          message: "File type must be jpeg or png",
        });
        setIsUploadLoading(false);
        return;
      }

      if (limit && size / 1024 / 1024 > limit) {
        if (setError)
          setError(fieldName, {
            type: "manual",
            message: "File size must be less than " + limit + "MB",
          });
        setIsUploadLoading(false);
        return;
      }

      let res = await axios.post(`/api/upload`, formData, {
        withCredentials: false,
      });

      const imagePaylod = {
        ...res.data[0],
        preview: URL.createObjectURL(file),
      };

      setImagePreview(URL.createObjectURL(file));

      if (setValue) setValue(fieldName, imagePaylod, { shouldValidate: true });
      if (onChange) onChange(imagePaylod);

      setIsUploadLoading(false);
    } catch (error) {
      if (setError)
        setError(fieldName, { message: "Upload failed. Please try again." });
      setIsUploadLoading(false);
    }
  };

  if (isButton)
    return (
      <Grid item xs={12}>
        <label>
          <Input
            type="file"
            sx={{ display: "none" }}
            onChange={onChangeHandler}
          />
          {register && (
            <input
              disabled={disabled}
              {...register(fieldName, {
                required: required ? "This field is required" : false,
              })}
              type="hidden"
            />
          )}
          {!register && <input type="hidden" />}
          <LoadingButton
            fullWidth
            disabled={disabled}
            loading={isUploadLoading}
            loadingPosition="end"
            endIcon={<FileUploadIcon />}
            variant="contained"
            component="span"
          >
            {label || "Upload"}
          </LoadingButton>
          <br />
          {fieldName && errors[fieldName] && (
            <Typography variant="caption" color="#fa541c">
              {errors[fieldName]?.message}
            </Typography>
          )}
        </label>
      </Grid>
    );

  return (
    <Card
      elevation={5}
      sx={{
        height: isText || isButton ? 120 : height || 300,
        width: width || 250,
      }}
    >
      <Grid container sx={{ width: "100%" }} p={2}>
        {!isText && !isButton && (
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            mb={2}
          >
            <Box
              component="img"
              sx={{
                width: 200,
                height: 200,
                objectFit: "contain",
              }}
              src={
                defaultValue
                  ? API_URL + defaultValue.url || defaultValue.preview
                  : imagePreview || "/upload.png"
              }
              alt={fieldName}
            />
          </Grid>
        )}
        {isText && (
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h6" color="text.secondary">
              {defaultValue?.url || defaultValue?.preview || "Carica file"}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <label>
            <Input
              type="file"
              sx={{ display: "none" }}
              onChange={onChangeHandler}
            />
            {register && (
              <input
                disabled={disabled}
                {...register(fieldName, {
                  required: required ? "This field is required" : false,
                })}
                type="hidden"
              />
            )}
            {!register && <input type="hidden" />}
            <LoadingButton
              fullWidth
              disabled={disabled}
              loading={isUploadLoading}
              loadingPosition="end"
              endIcon={<FileUploadIcon />}
              variant="contained"
              component="span"
            >
              {label || "Upload"}
            </LoadingButton>
            <br />
            {fieldName && errors[fieldName] && (
              <Typography variant="caption" color="#fa541c">
                {errors[fieldName]?.message}
              </Typography>
            )}
          </label>
        </Grid>
      </Grid>
    </Card>
  );
}
