import { Grid, Divider, Typography, Button } from "@pankod/refine-mui";

import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  const navigate = useNavigate();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom component="div">
          Conferma Pagamento
        </Typography>
        <Divider sx={{ my: 2 }} />
      </Grid>
      <Grid item xs={12}>
        <h1>Il pagamento è stato effettuato con successo</h1>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="link"
          onClick={() => {
            navigate("/");
          }}
        >
          Torna alla home
        </Button>
      </Grid>
    </Grid>
  );
}
