import { useState, useEffect, useLayoutEffect } from "react";

import {
  Box,
  Grid,
  Card,
  Typography,
  Button,
  Stack,
  Divider,
  Tab,
} from "@pankod/refine-mui";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { useOne } from "@pankod/refine-core";
import { useParams, useNavigate } from "react-router-dom";
import axios from "utils/axios";
import { normalizeData } from "utils/utils";
import { API_URL } from "constants";
import ParticipantList from "../components/partecipant/ParticipantList";
import { toast } from "react-toastify";

export default function Settings() {
  const [user, setUser] = useState(null);
  const [trip, setTrip] = useState(null);
  const [tripFields, setTripFields] = useState([]);
  const [selTab, setSelTab] = useState("1");

  const navigate = useNavigate();
  const params = useParams();
  const record = useOne({
    resource: "trips",
    id: params.id,
    metaData: {
      populate: "*",
    },
  });

  const IS_SCHOOL =
    user &&
    (user.role.name === "bl_scuola" ||
      user.role.name === "bl_amministrazione") &&
    trip &&
    !trip?.package_type?.title?.includes("Non");

  useEffect(() => {
    if (!record) return;
    const t = normalizeData(record?.data?.data);
    setTrip(t);

    if (trip && tripFields.length === 0) {
      const fields = [
        {
          name: "periodo",
          data: "departure_date",
          type: "date",
        },
        {
          name: "durata in giorni",
          data: "trip_duration_days",
          type: "text",
        },
        {
          name: "aeroporti di partenza",
          data: "departure_airports",
          type: "array",
          accessor: "title",
        },
        {
          name: "sistemazioni",
          data: "accommodations",
          type: "array",
          accessor: "title",
        },
        {
          name: "trattamento",
          data: "treatment",
          type: "object",
          accessor: "title",
        },
        {
          name: "corso di studi",
          data: "course_of_study",
          type: "text",
        },
        {
          name: "fascie di età",
          data: "age_range",
          type: "text",
        },
        {
          name: "attività",
          data: "activities",
          type: "array",
          accessor: "title",
        },
        {
          name: "trasferimenti",
          data: "transfer",
          type: "text",
        },
        {
          name: "escursioni e visite",
          data: "excursions_and_tours",
          type: "text",
        },
        {
          name: "assistenza e controllo",
          data: "support_and_supervision",
          type: "text",
        },
      ];
      setTripFields(fields);
    }
  }, [record, trip]);

  useEffect(() => {
    const fetchUser = async () => {
      const { data } = await axios.get("/api/users/me");
      setUser(data);
    };
    fetchUser();
  }, []);

  useLayoutEffect(() => {
    const tab = localStorage.getItem("selTab");
    if (tab && IS_SCHOOL) setSelTab(`${tab}`);
  }, [IS_SCHOOL]);

  const handleChange = (event, newValue) => {
    setSelTab(newValue);
    window.localStorage.setItem("selTab", newValue);
  };

  const TripTab = (
    <Grid container p={3}>
      <Grid item xs={12}>
        <Typography variant="h2" sx={{ mt: 5, mb: 3 }}>
          Pacchetto Acquistabile
        </Typography>
        <Divider sx={{ my: 1, mr: "30%" }} />
      </Grid>
      <Grid item xs={12} sx={{ mt: 7, mb: 3 }}>
        <Typography variant="h4" sx={{ mt: 5, mb: 3 }}>
          {trip?.title}
        </Typography>
        <Typography variant="body" sx={{ mt: 5, mb: 3 }}>
          {trip?.description}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ my: 3 }}>
        <Typography variant="h4" sx={{ mt: 5, mb: 3 }}>
          Informazioni Viaggio
        </Typography>
      </Grid>
      <Card>
        {tripFields.map((field, index) => {
          return (
            <Grid item xs={12} sx={{ p: 3 }} key={index}>
              <Typography variant="h5" sx={{ mb: 1 }}>
                {field.name}
              </Typography>
              <Typography variant="body" sx={{ mt: 2, mb: 1, ml: 3 }}>
                {field.type == "array"
                  ? trip[field.data]
                      .map((item) => item[field.accessor])
                      .join(", ")
                  : field.type == "object"
                  ? trip[field.data][field.accessor]
                  : trip[field.data]}
              </Typography>
              <Divider sx={{ my: 1 }} />
            </Grid>
          );
        })}
      </Card>
      <Grid item xs={12}>
        <Card sx={{ p: 3, my: 3 }}>
          <Grid container item xs={12} justifyContent="space-between">
            <Grid item xs={12} md={5}>
              <Typography variant="h4" sx={{ mb: 3 }}>
                Assicurazioni Obbligatorie
              </Typography>
              <Typography variant="body" sx={{ mt: 5, mb: 3 }}>
                {(trip?.insurance_policies || []).map((policy, index) => {
                  if (policy.type == "mandatory")
                    return (
                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        key={index}
                      >
                        <Grid item>
                          <Typography variant="body" sx={{ mt: 5, mb: 3 }}>
                            • {policy.name} - {Number(policy.price).toFixed(2)}{" "}
                            €
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              window.open(API_URL + policy.file.url, "_blank");
                            }}
                          >
                            Dettagli
                          </Button>
                        </Grid>
                      </Grid>
                    );
                })}
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <Typography variant="h4" sx={{ mb: 3 }}>
                Assicurazioni Opzionali
              </Typography>
              <Typography variant="body" sx={{ mt: 5, mb: 3 }}>
                {(trip?.insurance_policies || []).map((policy, index) => {
                  if (policy.type == "optional")
                    return (
                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        key={index}
                      >
                        <Grid item>
                          <Typography variant="body" sx={{ mt: 5, mb: 3 }}>
                            • {policy.name} - {Number(policy.price).toFixed(2)}{" "}
                            €
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              window.open(API_URL + policy.file.url, "_blank");
                            }}
                          >
                            Dettagli
                          </Button>
                        </Grid>
                      </Grid>
                    );
                })}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ p: 3, my: 3 }}>
          <Grid container item xs={12} justifyContent="space-between">
            <Grid item xs={12} md={5}>
              <Typography variant="h4" sx={{ mb: 3 }}>
                La Quota Comprende
              </Typography>
              <Typography variant="body" sx={{ mt: 5, mb: 3 }}>
                {trip?.fee_includes}
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <Typography variant="h4" sx={{ mb: 3 }}>
                La Quota NON Comprende
              </Typography>
              <Typography variant="body" sx={{ mt: 5, mb: 3 }}>
                {trip?.fee_not_includes}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <Grid item xs={5}>
          <Card sx={{ p: 3, my: 3 }}>
            <Grid
              item
              xs={12}
              container
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Grid item xs={12} md={7}>
                <Typography variant="h4">Per Informazioni:</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Stack direction="column" spacing={2}>
                  <Typography variant="body">
                    {trip?.agent?.first_name} {trip?.agent?.last_name}
                  </Typography>
                  <Typography variant="body">{trip?.agent?.email}</Typography>
                  <Typography variant="body">{trip?.agent?.phone}</Typography>
                </Stack>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ my: 2 }}>
        {user?.role?.name == "bl_amministrazione" && (
          <Grid item xs={12} container justifyContent="center">
            <Grid item xs={5}>
              <Button
                disabled={trip?.cancelled}
                fullWidth
                variant="contained"
                color="error"
                onClick={async () => {
                  if (
                    window.confirm(
                      "Sei sicuro di voler annullare il viaggio?"
                    ) == true
                  ) {
                    await axios.delete("/api/cancel-trip/" + trip.id);
                    toast.success("Viaggio annullato con successo");
                    navigate("/trips");
                  }
                }}
              >
                Annulla Viaggio
              </Button>
            </Grid>
          </Grid>
        )}
        {(user?.role?.name == "bl_nonscolastico" ||
          user?.role?.name == "bl_scolastico") && (
          <Grid item xs={12} container justifyContent="center">
            <Grid item xs={5}>
              <Button
                disabled={
                  trip?.cancelled ||
                  (trip?.personal_details || []).length >= trip?.max_attendee
                }
                fullWidth
                variant="contained"
                color="success"
                onClick={() => {
                  navigate("/trip-book-ns/" + trip.id);
                }}
              >
                {trip?.cancelled ||
                (trip?.personal_details || []).length >= trip?.max_attendee
                  ? "Viaggio non acquistabile"
                  : "Iscriviti al Viaggio"}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  return (
    <>
      <TabContext value={selTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Pacchetto" value="1" />
            {IS_SCHOOL && <Tab label="PARTECIPANTI" value="2" />}
            {IS_SCHOOL && <Tab label="ACCOMPAGNATORI" value="3" />}
          </TabList>
        </Box>
        <TabPanel value="1">{TripTab}</TabPanel>
        {IS_SCHOOL && (
          <TabPanel value="2">
            <ParticipantList trip={trip} type="participant" />
          </TabPanel>
        )}
        {IS_SCHOOL && (
          <TabPanel value="3">
            <ParticipantList trip={trip} type="escort" />
          </TabPanel>
        )}
      </TabContext>
    </>
  );
}
