import { useMemo } from "react";
import { EditButton, DeleteButton, Stack } from "@pankod/refine-mui";
import { Typography, useDataGrid, DataGrid, List } from "@pankod/refine-mui";

function UserList() {
  const columns = useMemo(
    () => [
      { field: "first_name", headerName: "Nome", flex: 1, minWidth: 200 },
      { field: "last_name", headerName: "Cognome", flex: 1, minWidth: 200 },
      { field: "email", headerName: "Email", flex: 1, minWidth: 200 },
      { field: "phone", headerName: "Telefono", flex: 1, minWidth: 200 },
      {
        field: "role",
        headerName: "Ruolo",
        flex: 1,
        minWidth: 200,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              {params.row?.role?.name.substring(3, 99)}
            </Stack>
          );
        },
      },
      {
        headerName: "Actions",
        field: "actions",
        minWidth: 50,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} />
            </Stack>
          );
        },
      },
    ],
    []
  );

  const { dataGridProps, sortingMode, sortModel, onSortModelChange } =
    useDataGrid();

  return (
    <List
      resource="users"
      createButtonProps={{ size: "small" }}
      headerProps={{
        title: <Typography variant="h5">Utenti</Typography>,
      }}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        sortingMode={sortingMode}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        autoHeight
        pagination
      />
    </List>
  );
}

export default UserList;
