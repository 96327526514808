import { useState, useEffect } from "react";
import { TOKEN_KEY, API_URL } from "./constants";
// refine
import {
  ErrorComponent,
  ReadyPage,
  CssBaseline,
  createTheme,
  ThemeProvider,
  GlobalStyles,
  RefineSnackbarProvider,
  notificationProvider,
  AuthPage,
} from "@pankod/refine-mui";
// refine utils
import { Refine, Authenticated } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { AuthHelper } from "@pankod/refine-strapi-v4";
import { DataProvider } from "strapiDataProvider";

// utils
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "utils/axios";

// custom pages and components
import CustomLayout from "components/CustomLayout";
import CustomCreateEdit from "components/CustomCreateEdit";
import LoginPage from "components/user/LoginPage";
import RegisterPage from "components/user/RegisterPage";
import PasswordForgot from "components/user/PasswordForgot";
import PasswordReset from "components/user/PasswordReset";
import Dashboard from "pages/Dashboard";
import Settings from "pages/Settings";

// resources
import TripTypeCreate from "pages/management/trip-type/Create";
import TripTypeEdit from "pages/management/trip-type/Edit";
import TripTypeList from "pages/management/trip-type/List";

// import PackageTypeCreate from "pages/management/package-type/Create";
// import PackageTypeEdit from "pages/management/package-type/Edit";
import PackageTypeList from "pages/management/package-type/List";

import DestinationCreate from "pages/management/destination/Create";
import DestinationEdit from "pages/management/destination/Edit";
import DestinationList from "pages/management/destination/List";

import AccommodationCreate from "pages/management/accommodation/Create";
import AccommodationEdit from "pages/management/accommodation/Edit";
import AccommodationList from "pages/management/accommodation/List";

import AirportCreate from "pages/management/departure-airport/Create";
import AirportEdit from "pages/management/departure-airport/Edit";
import AirportList from "pages/management/departure-airport/List";

import AllergyCreate from "pages/management/allergy/Create";
import AllergyEdit from "pages/management/allergy/Edit";
import AllergyList from "pages/management/allergy/List";

import TreatmentCreate from "pages/management/treatment/Create";
import TreatmentEdit from "pages/management/treatment/Edit";
import TreatmentList from "pages/management/treatment/List";

import VaccineCreate from "pages/management/vaccine/Create";
import VaccineEdit from "pages/management/vaccine/Edit";
import VaccineList from "pages/management/vaccine/List";

import LanguageCreate from "pages/management/language/Create";
import LanguageEdit from "pages/management/language/Edit";
import LanguageList from "pages/management/language/List";

import LanguageLevelCreate from "pages/management/language-level/Create";
import LanguageLevelEdit from "pages/management/language-level/Edit";
import LanguageLevelList from "pages/management/language-level/List";

import AgentCreate from "pages/management/agent/Create";
import AgentEdit from "pages/management/agent/Edit";
import AgentList from "pages/management/agent/List";

import ActivityCreate from "pages/management/activity/Create";
import ActivityEdit from "pages/management/activity/Edit";
import ActivityList from "pages/management/activity/List";

import ActivityTypeCreate from "pages/management/activity-type/Create";
import ActivityTypeEdit from "pages/management/activity-type/Edit";
import ActivityTypeList from "pages/management/activity-type/List";

import TripCreate from "pages/trip/Create";
import TripEdit from "pages/trip/Edit";
import TripList from "pages/trip/List";
import TripView from "pages/TripView";

import ServiceTypeCreate from "pages/management/service-type/Create";
import ServiceTypeEdit from "pages/management/service-type/Edit";
import ServiceTypeList from "pages/management/service-type/List";

import ServiceFields from "pages/management/service/Fields";
import ServiceList from "pages/management/service/List";

import GuideTypeCreate from "pages/management/guide-type/Create";
import GuideTypeEdit from "pages/management/guide-type/Edit";
import GuideTypeList from "pages/management/guide-type/List";

import GuideFields from "pages/management/guide/Fields";
import GuideList from "pages/management/guide/List";

import InterestPointTypeCreate from "pages/management/interest-point-type/Create";
import InterestPointTypeEdit from "pages/management/interest-point-type/Edit";
import InterestPointTypeList from "pages/management/interest-point-type/List";

import InterestPointFields from "pages/management/interest-point/Fields";
import InterestPointList from "pages/management/interest-point/List";

import DailyScheduleCreate from "pages/management/daily-schedule/Create";
import DailyScheduleEdit from "pages/management/daily-schedule/Edit";
import DailyScheduleList from "pages/management/daily-schedule/List";

import ScheduleCreate from "pages/management/schedule/Create";
import ScheduleEdit from "pages/management/schedule/Edit";
import ScheduleList from "pages/management/schedule/List";

import PaymentList from "pages/payment/List";

import DocumentList from "pages/document/List";

// Utils
import PaymentSuccess from "pages/PaymentSuccess";

// Non School User
import DocumentListNS from "pages/nonschool/document/List";
import TripListNS from "pages/nonschool/trip/List";
import TripStepperNS from "components/trip-steppers/TripStepperNS";
import PaymentListNS from "pages/nonschool/payment/List";

// School User
import SchoolTripList from "pages/school/trip/List";
import SchoolUserList from "pages/school/users/List";

// Users
import UserCreate from "pages/user/Create";
import UserList from "pages/user/List";
import UserEdit from "pages/user/Edit";

// Icons
import {
  People,
  AddCard,
  Description,
  ConnectingAirports,
  Task,
  AssignmentTurnedIn,
  SupportAgent,
  AirlineStops,
  FlightTakeoff,
  OtherHouses,
  Map,
  WarningAmber,
  Vaccines,
  Translate,
  Equalizer,
  Spa,
  ModeOfTravel,
  ManageAccounts,
} from "@mui/icons-material";

export const themeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#8d99ae",
    },
    secondary: {
      main: "#2b2d42",
    },
    info: {
      main: "#edf2f4",
    },
  },
};

const theme = createTheme(themeOptions);
const queryClient = new QueryClient();
window.queryClient = queryClient;

const adminResources = [
  {
    name: "Gestione",
    icon: <ManageAccounts />,
    options: { label: "Gestione" },
  },
  {
    name: "trip-types",
    parentName: "Gestione",
    icon: <ModeOfTravel />,
    options: { label: "Tipi Viaggio" },
    list: TripTypeList,
    create: TripTypeCreate,
    edit: TripTypeEdit,
  },
  {
    name: "package-types",
    parentName: "Gestione",
    // icon: <Icon />,
    options: { label: "Tipi Pacchetto" },
    list: PackageTypeList,
    // create: PackageTypeCreate,
    // edit: PackageTypeEdit,
  },
  {
    name: "destinations",
    parentName: "Gestione",
    icon: <Map />,
    options: { label: "Destinazioni" },
    list: DestinationList,
    create: DestinationCreate,
    edit: DestinationEdit,
  },
  {
    name: "accommodations",
    parentName: "Gestione",
    icon: <OtherHouses />,
    options: { label: "Sistemazioni" },
    list: AccommodationList,
    create: AccommodationCreate,
    edit: AccommodationEdit,
  },
  {
    name: "departure-airports",
    parentName: "Gestione",
    icon: <FlightTakeoff />,
    options: { label: "Aeroporti" },
    list: AirportList,
    create: AirportCreate,
    edit: AirportEdit,
  },
  {
    name: "allergies",
    parentName: "Gestione",
    icon: <WarningAmber />,
    options: { label: "Allergie" },
    list: AllergyList,
    create: AllergyCreate,
    edit: AllergyEdit,
  },
  {
    name: "vaccines",
    parentName: "Gestione",
    icon: <Vaccines />,
    options: { label: "Vaccinazioni" },
    list: VaccineList,
    create: VaccineCreate,
    edit: VaccineEdit,
  },
  {
    name: "languages",
    parentName: "Gestione",
    icon: <Translate />,
    options: { label: "Lingue" },
    list: LanguageList,
    create: LanguageCreate,
    edit: LanguageEdit,
  },
  {
    name: "language-levels",
    parentName: "Gestione",
    icon: <Equalizer />,
    options: { label: "Livelli Lingua" },
    list: LanguageLevelList,
    create: LanguageLevelCreate,
    edit: LanguageLevelEdit,
  },
  {
    name: "treatments",
    parentName: "Gestione",
    icon: <Spa />,
    options: { label: "Trattamenti" },
    list: TreatmentList,
    create: TreatmentCreate,
    edit: TreatmentEdit,
  },
  {
    name: "agents",
    parentName: "Gestione",
    icon: <SupportAgent />,
    options: { label: "Agenti" },
    list: AgentList,
    create: AgentCreate,
    edit: AgentEdit,
  },
  {
    name: "activity-types",
    parentName: "Gestione",
    icon: <AssignmentTurnedIn />,
    options: { label: "Tipi Attività" },
    list: ActivityTypeList,
    create: ActivityTypeCreate,
    edit: ActivityTypeEdit,
  },
  {
    name: "activities",
    parentName: "Gestione",
    icon: <Task />,
    options: { label: "Attività" },
    list: ActivityList,
    create: ActivityCreate,
    edit: ActivityEdit,
    metaData: {
      populate: "*",
    },
  },
  {
    name: "service-types",
    parentName: "Gestione",
    icon: <Task />,
    options: { label: "Tipi Servizio" },
    list: ServiceTypeList,
    create: ServiceTypeCreate,
    edit: ServiceTypeEdit,
  },
  {
    name: "services",
    parentName: "Gestione",
    icon: <Task />,
    options: { label: "Servizi" },
    list: ServiceList,
    create: () => CustomCreateEdit({ isCreate: true, Fields: ServiceFields }),
    edit: () => CustomCreateEdit({ isCreate: false, Fields: ServiceFields }),
  },
  {
    name: "guide-types",
    parentName: "Gestione",
    icon: <Task />,
    options: { label: "Tipi Guide" },
    list: GuideTypeList,
    create: GuideTypeCreate,
    edit: GuideTypeEdit,
  },
  {
    name: "guides",
    parentName: "Gestione",
    icon: <Task />,
    options: { label: "Guide" },
    list: GuideList,
    create: () => CustomCreateEdit({ isCreate: true, Fields: GuideFields }),
    edit: () => CustomCreateEdit({ isCreate: false, Fields: GuideFields }),
  },
  {
    name: "interest-point-types",
    parentName: "Gestione",
    icon: <Task />,
    options: { label: "Tipi Punti di Interesse" },
    list: InterestPointTypeList,
    create: InterestPointTypeCreate,
    edit: InterestPointTypeEdit,
  },
  {
    name: "interest-points",
    parentName: "Gestione",
    icon: <Task />,
    options: { label: "Punti di Interesse" },
    list: InterestPointList,
    create: () =>
      CustomCreateEdit({ isCreate: true, Fields: InterestPointFields }),
    edit: () =>
      CustomCreateEdit({ isCreate: false, Fields: InterestPointFields }),
  },
  {
    name: "daily-schedules",
    icon: <Task />,
    options: { label: "Piani Giornalieri" },
    list: DailyScheduleList,
    create: DailyScheduleCreate,
    edit: DailyScheduleEdit,
  },
  {
    name: "schedules",
    icon: <Task />,
    options: { label: "Piani Viaggio" },
    list: ScheduleList,
    create: ScheduleCreate,
    edit: ScheduleEdit,
  },
  {
    name: "trips",
    icon: <ConnectingAirports />,
    options: { label: "Viaggi" },
    list: TripList,
    create: TripCreate,
    edit: TripEdit,
  },
  {
    name: "user-attachments",
    icon: <Description />,
    options: { label: "Documenti" },
    list: DocumentList,
  },
  {
    name: "payments",
    icon: <AddCard />,
    options: { label: "Pagamenti" },
    list: PaymentList,
  },
  {
    name: "users",
    icon: <People />,
    options: { label: "Utenti" },
    list: UserList,
    create: UserCreate,
    edit: UserEdit,
  },
];

const nonSchoolResources = [
  {
    name: "trips",
    icon: <ConnectingAirports />,
    options: { label: "Viaggi" },
    list: TripListNS,
  },
  {
    name: "user-attachments",
    icon: <Description />,
    options: { label: "Documenti" },
    list: DocumentListNS,
  },
  {
    name: "payments",
    icon: <AddCard />,
    options: { label: "Pagamenti" },
    list: PaymentListNS,
  },
];

const scholasticResources = [
  {
    name: "trips",
    icon: <ConnectingAirports />,
    options: { label: "Viaggi" },
    list: TripListNS,
  },
  {
    name: "user-attachments",
    icon: <Description />,
    options: { label: "Documenti" },
    list: DocumentListNS,
  },
];

const schoolResources = [
  {
    name: "trips",
    icon: <ConnectingAirports />,
    options: { label: "Viaggi" },
    list: SchoolTripList,
  },
  {
    name: "user-attachments",
    icon: <Description />,
    options: { label: "Documenti" },
    list: DocumentList,
  },
  // {
  //   name: "payments",
  //   icon: <AddCard />,
  //   options: { label: "Pagamenti" },
  //   list: PaymentList,
  // },
  {
    name: "school-users",
    icon: <People />,
    options: { label: "Studenti" },
    list: SchoolUserList,
  },
];

const App = () => {
  const [user, setUser] = useState(null);
  const [selectedResources, setSelectedResources] =
    useState(nonSchoolResources);

  useEffect(() => {
    const refresh = async () => {
      let auth = localStorage.getItem("user");
      if (auth) {
        await authProvider.getUserIdentity();
        auth = localStorage.getItem("user");
        const parsedUser = JSON.parse(auth);
        setUser(parsedUser);
        if (parsedUser?.role?.name === "bl_amministrazione") {
          setSelectedResources(adminResources);
          console.log("ADMIN");
        }
        if (parsedUser?.role?.name === "bl_nonscolastico") {
          setSelectedResources(nonSchoolResources);
          console.log("NON SCHOOL");
        }
        if (parsedUser?.role?.name === "bl_scuola") {
          setSelectedResources(schoolResources);
          console.log("SCHOOL");
        }
        if (parsedUser?.role?.name === "bl_scolastico") {
          setSelectedResources(scholasticResources);
          console.log("SCHOLASTIC");
        }
      } else {
        setSelectedResources(scholasticResources);
        console.log("SCHOLASTIC");
      }
    };
    refresh();
  }, [localStorage.getItem("user")]);

  const strapiAuthHelper = AuthHelper(API_URL + "/api");

  const authProvider = {
    login: async ({ username, password }) => {
      const { data, status } = await strapiAuthHelper.login(username, password);
      if (status === 200) {
        localStorage.setItem(TOKEN_KEY, data.jwt);
        localStorage.setItem("user", JSON.stringify(data.user));
        setUser(data.user);
        // set header axios instance
        axios.defaults.headers.common.Authorization = `Bearer ${data.jwt}`;

        return Promise.resolve();
      }
      return Promise.reject();
    },
    register: async ({
      first_name,
      last_name,
      birth_date,
      phone,
      email,
      password,
    }) => {
      const { data, status } = await axios.post("/api/auth/local/register", {
        first_name,
        last_name,
        birth_date,
        phone,
        email,
        password,
      });
      if (status === 200) {
        localStorage.setItem(TOKEN_KEY, data.jwt);
        localStorage.setItem("user", JSON.stringify(data.user));
        setUser(data.user);
        // set header axios instance
        axios.defaults.headers.common.Authorization = `Bearer ${data.jwt}`;

        return Promise.resolve();
      }
      return Promise.reject();
    },
    logout: () => {
      localStorage.removeItem(TOKEN_KEY);
      return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
      const token = localStorage.getItem(TOKEN_KEY);
      const auth = localStorage.getItem("user");
      if (auth) {
        const parsedUser = JSON.parse(auth);
        setUser(parsedUser);
      }
      if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        return Promise.resolve();
      }

      return Promise.reject();
    },
    getPermissions: () => {
      const auth = localStorage.getItem("user");
      if (auth) {
        const parsedUser = JSON.parse(auth);
        setUser(parsedUser);
        return Promise.resolve(parsedUser.role.type);
      }
      return Promise.reject();
    },
    getUserIdentity: async () => {
      const token = localStorage.getItem(TOKEN_KEY);
      if (!token) {
        return Promise.reject();
      }
      const { data, status } = await strapiAuthHelper.me(token);
      localStorage.setItem("user", JSON.stringify(data));
      if (status === 200) {
        const { id, username, email } = data;
        return Promise.resolve({
          id,
          username,
          email,
        });
      }

      return Promise.reject();
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
      <RefineSnackbarProvider>
        <QueryClientProvider client={queryClient}>
          <Refine
            routerProvider={{
              ...routerProvider,
              routes: [
                {
                  path: "/register",
                  element: <RegisterPage />,
                  layout: false,
                },
                {
                  element: <PasswordForgot />,
                  path: "/forgot-password",
                  layout: false,
                },
                {
                  element: <PasswordReset />,
                  path: "/reset-password",
                  layout: false,
                },
                {
                  element: <PaymentSuccess />,
                  path: "/success",
                  layout: true,
                },
                {
                  element: (
                    <Authenticated>
                      <TripView />
                    </Authenticated>
                  ),
                  path: "/trip-view/:id",
                  layout: true,
                },
                {
                  element: (
                    <Authenticated>
                      <Settings />
                    </Authenticated>
                  ),
                  path: "/settings",
                  layout: true,
                },
                {
                  element: (
                    <Authenticated>
                      <TripStepperNS />
                    </Authenticated>
                  ),
                  path: "/trip-book-ns/:id",
                  layout: true,
                },
              ],
            }}
            resources={selectedResources}
            authProvider={authProvider}
            dataProvider={DataProvider(API_URL + "/api", axios)}
            notificationProvider={notificationProvider}
            Title={({ collapsed }) => (
              <div
                style={{
                  height: 200,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {collapsed && (
                  <img src="/logo.png" alt="Logo" style={{ width: "50px" }} />
                )}
                {!collapsed && (
                  <img src="/logo.png" alt="Logo" style={{ width: "100px" }} />
                )}
              </div>
            )}
            Layout={CustomLayout}
            DashboardPage={Dashboard}
            ReadyPage={ReadyPage}
            LoginPage={LoginPage}
            catchAll={<ErrorComponent />}
          />
        </QueryClientProvider>
      </RefineSnackbarProvider>
      <ToastContainer />
    </ThemeProvider>
  );
};

export default App;
