import { useMemo, useState, useEffect } from "react";
import { Button, Stack } from "@pankod/refine-mui";
import { Typography, useDataGrid, DataGrid, List } from "@pankod/refine-mui";
import axios from "utils/axios";

export default function CompList() {
  const columns = useMemo(
    () => [
      {
        field: "trip",
        headerName: "Nome Viaggio",
        flex: 1,
        minWidth: 200,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              {params.row?.trip?.title}
            </Stack>
          );
        },
      },
      {
        field: "description",
        headerName: "Nome Pagamento",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "payment_type",
        headerName: "Tipo Pagamento",
        flex: 1,
        minWidth: 100,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              {params.row?.payment_type == "days" ? "Rata" : "Saldo"}
            </Stack>
          );
        },
      },
      {
        field: "sum",
        headerName: "Importo",
        flex: 1,
        minWidth: 100,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              {Number(params.row?.sum).toFixed(2) + " €"}
            </Stack>
          );
        },
      },
      {
        field: "status",
        headerName: "Stato",
        flex: 1,
        minWidth: 200,
        renderCell: function render(params) {
          return (
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="center"
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor:
                  params.row?.status == "paid" ? "success.main" : "error.main",
              }}
            >
              {params.row?.status == "paid" ? "Pagato" : "Non Pagato"}
            </Stack>
          );
        },
      },
      {
        field: "actions",
        headerName: "Azioni",
        flex: 1,
        minWidth: 200,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
              <Button
                fullWidth
                disabled={
                  (params.row?.status == "paid" ? true : false) ||
                  (params.row?.trip?.cancelled ? true : false)
                }
                color={params.row?.status == "paid" ? "inherit" : "success"}
                onClick={async () => {
                  let { data } = await axios.get(
                    "/api/payment-checkout-session/" + params.row.id
                  );
                  window.location = data;
                }}
              >
                Paga
              </Button>
            </Stack>
          );
        },
      },
      // {
      //   headerName: "Actions",
      //   field: "actions",
      //   minWidth: 50,
      //   renderCell: function render(params) {
      //     return (
      //       <Stack direction="row" spacing={1}>
      //         <EditButton hideText recordItemId={params.row.id} />
      //         <DeleteButton hideText recordItemId={params.row.id} />
      //       </Stack>
      //     );
      //   },
      // },
    ],
    []
  );

  const { dataGridProps, sortingMode, sortModel, onSortModelChange } =
    useDataGrid({
      metaData: {
        populate: "*",
      },
    });

  return (
    <List
      resource="payments"
      createButtonProps={{ size: "small" }}
      headerProps={{
        title: <Typography variant="h5">Pagamenti</Typography>,
      }}
      canCreate={false}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        sortingMode={sortingMode}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        autoHeight
      />
    </List>
  );
}
