import { useMemo, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Dialog,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
} from "@pankod/refine-mui";
import { Typography, useDataGrid, DataGrid, List } from "@pankod/refine-mui";

import axios from "utils/axios";

export default function CompList() {
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const getDetails = async (id) => {
    setLoading(true);
    try {
      const { data } = await axios.get(`/api/payment-details/${id}`);
      setSelectedUser({
        name: data.name,
        email: data.email,
        address: data.address,
        tax_info: data.tax_info,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedUser) {
      getDetails(selectedUser);
    }
  }, [selectedUser]);

  const columns = useMemo(
    () => [
      {
        field: "personal_detail",
        headerName: "Utente",
        flex: 1,
        minWidth: 250,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <Button
                variant="link"
                onClick={() => setSelectedUser(params.row?.id)}
              >
                {params.row?.personal_detail?.first_name +
                  " " +
                  params.row?.personal_detail?.last_name}
              </Button>
            </Stack>
          );
        },
      },
      {
        field: "trip",
        headerName: "Nome Viaggio",
        flex: 1,
        minWidth: 200,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              {params.row?.trip?.title}
            </Stack>
          );
        },
      },
      {
        field: "description",
        headerName: "Nome Pagamento",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "payment_type",
        headerName: "Tipo Pagamento",
        flex: 1,
        minWidth: 100,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              {params.row?.payment_type == "days" ? "Rata" : "Saldo"}
            </Stack>
          );
        },
      },
      {
        field: "sum",
        headerName: "Importo",
        flex: 1,
        minWidth: 100,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              {Number(params.row?.sum).toFixed(2) + " €"}
            </Stack>
          );
        },
      },
      {
        field: "status",
        headerName: "Stato",
        flex: 1,
        minWidth: 200,
        renderCell: function render(params) {
          return (
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="center"
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor:
                  params.row?.status == "paid" ? "success.main" : "error.main",
              }}
            >
              {params.row?.status == "paid" ? "Pagato" : "Non Pagato"}
            </Stack>
          );
        },
      },

      // {
      //   headerName: "Actions",
      //   field: "actions",
      //   minWidth: 50,
      //   renderCell: function render(params) {
      //     return (
      //       <Stack direction="row" spacing={1}>
      //         <EditButton hideText recordItemId={params.row.id} />
      //         <DeleteButton hideText recordItemId={params.row.id} />
      //       </Stack>
      //     );
      //   },
      // },
    ],
    []
  );

  const { dataGridProps, sortingMode, sortModel, onSortModelChange } =
    useDataGrid({
      metaData: {
        populate: "*",
      },
    });

  return (
    <List
      resource="payments"
      createButtonProps={{ size: "small" }}
      headerProps={{
        title: <Typography variant="h5">Pagamenti</Typography>,
      }}
      canCreate={false}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        sortingMode={sortingMode}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        autoHeight
      />
      <Dialog
        open={selectedUser !== null}
        onClose={() => setSelectedUser(null)}
        maxWidth={false}
      >
        {loading && (
          <Grid container p={5} justifyContent="center" alignItems="center">
            <CircularProgress />
          </Grid>
        )}
        {!loading && selectedUser && selectedUser.email && (
          <Grid container spacing={2} p={3}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Paese</TableCell>
                  <TableCell>Stato</TableCell>
                  <TableCell>Città</TableCell>
                  <TableCell>Indirizzo</TableCell>
                  <TableCell>Indirizzo 2</TableCell>
                  <TableCell>CAP</TableCell>
                  <TableCell>Partita IVA</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{selectedUser?.name}</TableCell>
                  <TableCell>{selectedUser?.email}</TableCell>
                  <TableCell>{selectedUser?.address?.country}</TableCell>
                  <TableCell>{selectedUser?.address?.state}</TableCell>
                  <TableCell>{selectedUser?.address?.city}</TableCell>
                  <TableCell>{selectedUser?.address?.line1}</TableCell>
                  <TableCell>{selectedUser?.address?.line2}</TableCell>
                  <TableCell>{selectedUser?.address?.postal_code}</TableCell>
                  <TableCell>{selectedUser?.tax_info}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        )}
        {!loading && selectedUser && !selectedUser.email && (
          <Grid container p={5} justifyContent="center" alignItems="center">
            <Typography variant="h5" align="center">
              Nessun dettaglio disponibile
            </Typography>
          </Grid>
        )}
      </Dialog>
    </List>
  );
}
