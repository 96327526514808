import { Stack, Grid, Button } from "@pankod/refine-mui";
import axios from "utils/axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useOne, useQueryClient } from "@pankod/refine-core";
import { normalizeData } from "utils/utils";

import Fields from "./ParticipantFields";
import { useEffect } from "react";

export default function ParticipantEdit({ userId, trip, escort }) {
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {}, [userId]);

  const record = useOne({
    resource: "users",
    id: userId,
    // metaData: {
    //   populate: "*",
    // },
  });

  const recordNorm = normalizeData({
    ...record?.data,
    insurance_policies: record?.insurance_policies || [],
    languages: record?.languages || [],
  });

  // console.log({ recordNorm });

  if (!recordNorm) return null;

  const onSubmit = async (values) => {
    const { insurance_policies: ip, ...user } = values;
    const {
      birth_date,
      first_name,
      last_name,
      email,
      phone,
      languages,
      gratuity,
    } = user;

    let ins = [];
    if (Array.isArray(ip)) {
      ins = typeof ip[0] === "object" ? [...ip.map((i) => i.id)] : [...ip];
    }

    const user_insurance_policies = ins.map((p) => {
      return trip.insurance_policies.find((i) => i.name === p);
    });

    const userData = {
      birth_date,
      first_name,
      last_name,
      email,
      phone,
      insurance_policies: user_insurance_policies || [],
      escort,
      trip_id: trip.id,
      role: "bl_scolastico",
      languages,
      gratuity,
    };

    console.log(userData);
    try {
      await axios.post("/api/invite-user", userData);

      queryClient.invalidateQueries();
      toast.success(`${escort ? "Accompagnatore" : "Partecipante"} aggiornato`);
    } catch (error) {
      toast.error(
        `Errore aggiornando ${escort ? "Accompagnatore" : "Partecipante"}`
      );
    }
  };

  return (
    <Grid component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2} direction="column">
        <Fields
          register={register}
          control={control}
          errors={errors}
          getValues={getValues}
          record={recordNorm}
          setValue={setValue}
          trip={trip}
        />
        <div>
          <Button type="submit" variant="contained">
            {`Modifica ${escort ? "Accompagnatore" : "Partecipante"}`}
          </Button>
        </div>
      </Stack>
    </Grid>
  );
}
