import { useMemo } from "react";
import { EditButton, DeleteButton, Stack, Button } from "@pankod/refine-mui";
import { Typography, useDataGrid, DataGrid, List } from "@pankod/refine-mui";

import { useNavigate } from "@pankod/refine-react-router-v6";

export default function IngredientList() {
  const navigate = useNavigate();
  const columns = useMemo(
    () => [
      {
        field: "trip_code",
        headerName: "Codice Viaggio",
        flex: 1,
        minWidth: 100,
      },
      { field: "title", headerName: "Nome Viaggio", flex: 1, minWidth: 200 },
      {
        field: "destination",
        headerName: "Destinazione",
        flex: 1,
        minWidth: 200,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              {params.row?.destination?.title}
            </Stack>
          );
        },
      },
      {
        field: "trip_type",
        headerName: "Tipo Viaggio",
        flex: 1,
        minWidth: 200,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              {params.row?.trip_type?.title}
            </Stack>
          );
        },
      },
      {
        field: "package_type",
        headerName: "Tipo Pacchetto",
        flex: 1,
        minWidth: 200,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              {params.row?.package_type?.title}
            </Stack>
          );
        },
      },
      {
        headerName: "Actions",
        field: "actions",
        minWidth: 150,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <Button
                color="success"
                size="small"
                onClick={() => {
                  navigate(`/trip-view/${params.row.id}`);
                }}
              >
                Dettagli
              </Button>
            </Stack>
          );
        },
      },
    ],
    []
  );

  const { dataGridProps, sortingMode, sortModel, onSortModelChange } =
    useDataGrid({
      metaData: {
        populate: "*",
      },
    });

  return (
    <List
      resource="trips"
      createButtonProps={{ size: "small" }}
      headerProps={{
        title: <Typography variant="h5">Viaggi</Typography>,
      }}
      canCreate={false}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        sortingMode={sortingMode}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        autoHeight
      />
    </List>
  );
}
