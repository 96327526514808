import { useState } from "react";

import {
  Stack,
  Box,
  TextField,
  MenuItem,
  Grid,
  Card,
  Switch,
  Autocomplete,
  useAutocomplete,
} from "@pankod/refine-mui";

import { Controller } from "@pankod/refine-react-hook-form";

export default function CompFields({
  register,
  control,
  errors,
  record,
  getValues,
}) {
  return (
    <Stack component="form" autoComplete="off" spacing={2}>
      {/* ---- Text ---- */}
      <TextField
        {...register("first_name", { required: "Nome è Obbligatorio" })}
        InputLabelProps={{
          shrink: true,
        }}
        error={!!errors?.first_name}
        helperText={errors.first_name?.message}
        required
        fullWidth
        label="first_name"
      />
      <TextField
        {...register("last_name", { required: "Cognome è Obbligatorio" })}
        InputLabelProps={{
          shrink: true,
        }}
        error={!!errors?.last_name}
        helperText={errors.last_name?.message}
        required
        fullWidth
        label="last_name"
      />
      <Stack spacing={2} direction="row">
        <TextField
          {...register("email", { required: "Email è Obbligatoria" })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.email}
          helperText={errors.email?.message}
          required
          fullWidth
          label="email"
          type={"email"}
        />
        <TextField
          {...register("phone", { required: "Telefono è Obbligatorio" })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.phone}
          helperText={errors.phone?.message}
          required
          fullWidth
          label="phone"
          type={"phone"}
        />
      </Stack>
    </Stack>
  );
}
