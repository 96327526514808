import React from "react";
import FileViewer from "react-file-viewer";

function Viewer({ file, type }) {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <FileViewer
        fileType={type}
        filePath={file}
        // errorComponent={CustomErrorComponent}
        onError={(err) => {
          console.log(err);
        }}
      />
    </div>
  );
}

export default Viewer;
