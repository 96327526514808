import { Stack, Grid, Button } from "@pankod/refine-mui";
import axios from "utils/axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@pankod/refine-core";

import Fields from "./ParticipantFields";

export default function ParticipantCreate({ userId, trip, escort }) {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm();

  const queryClient = useQueryClient();

  const onSubmit = async (values) => {
    const { insurance_policies: ip, ...user } = values;
    const {
      birth_date,
      first_name,
      last_name,
      email,
      phone,
      languages,
      gratuity,
    } = user;

    console.log(ip, trip.insurance_policies);
    const user_insurance_policies = ip.map((p) =>
      trip.insurance_policies.find((i) => i.name === p)
    );

    const userData = {
      birth_date,
      first_name,
      last_name,
      email,
      phone,
      insurance_policies: user_insurance_policies || [],
      escort,
      trip_id: trip.id,
      role: "bl_scolastico",
      languages,
      gratuity,
    };

    console.log(userData);
    try {
      const resp = await axios.post("/api/invite-user", userData);
      console.log(resp);

      queryClient.invalidateQueries();
      toast.success(`${escort ? "Accompagnatore" : "Partecipante"} creato`);
    } catch (error) {
      toast.error(
        `Errore nella creazione di ${
          escort ? "Accompagnatore" : "Partecipante"
        }`
      );
    }
  };

  return (
    <Grid component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2} direction="column">
        <Fields
          register={register}
          control={control}
          errors={errors}
          getValues={getValues}
          trip={trip}
        />
        <div>
          <Button type="submit" variant="contained">
            {`Aggiungi ${escort ? "Accompagnatore" : "Partecipante"}`}
          </Button>
        </div>
      </Stack>
    </Grid>
  );
}
