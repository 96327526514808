import { useState } from "react";

import {
  Stack,
  Box,
  TextField,
  MenuItem,
  Grid,
  Card,
  Switch,
  Autocomplete,
  useAutocomplete,
} from "@pankod/refine-mui";

import { Controller } from "@pankod/refine-react-hook-form";

export default function CompFields({
  register,
  control,
  errors,
  record,
  getValues,
}) {
  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column" }}
      autoComplete="off"
    >
      {/* ---- Text ---- */}
      <TextField
        {...register("title", { required: "Aeroporto Obbligatorio" })}
        InputLabelProps={{
          shrink: true,
        }}
        error={!!errors?.title}
        helperText={errors.title?.message}
        required
        fullWidth
        label="Aeroporto"
      />
    </Box>
  );
}
