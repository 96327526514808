import { useState, useEffect, useRef } from "react";

import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  Typography,
} from "@pankod/refine-mui";

export default function CompFields({ getValues, customData }) {
  // base fields

  const selectedPolicies = (customData?.trip?.insurance_policies || []).filter(
    (x) =>
      x.type == "optional" && getValues("optional_insurances")?.includes(x.name)
  );

  let totalPrice =
    Number(customData?.trip?.price) +
    selectedPolicies?.reduce((a, b) => Number(a) + Number(b.price), 0) +
    (customData?.trip?.insurance_policies || [])
      .filter((x) => x.type == "mandatory")
      .reduce((a, b) => Number(a) + Number(b.price), 0);

  // add 10%
  // totalPrice = totalPrice * 1.1;

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom component="div">
            Riepilogo Costi
          </Typography>
          <Divider sx={{ mb: 2 }} />
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          p={3}
        >
          <TableContainer
            sx={{
              width: "100%",
              height: "100%",
              overflow: "auto",
              maxHeight: "300px",
            }}
          >
            <Table>
              <TableHead
                sx={{
                  backgroundColor: "primary.main",
                  color: "primary.contrastText",
                }}
              >
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">Oggetto</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Costo</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{customData.trip.title}</TableCell>
                  <TableCell>
                    {Number(customData?.trip?.price || 0).toFixed(2)} €
                  </TableCell>
                </TableRow>
                {(customData?.trip?.insurance_policies || []).map(
                  (policy, index) => {
                    if (policy.type == "mandatory")
                      return (
                        <TableRow>
                          <TableCell>{policy.name}</TableCell>
                          <TableCell>
                            {Number(policy?.price || 0).toFixed(2)} €
                          </TableCell>
                        </TableRow>
                      );
                  }
                )}
                {(selectedPolicies || []).map((policy, index) => {
                  return (
                    <TableRow>
                      <TableCell>{policy.name}</TableCell>
                      <TableCell>
                        {Number(policy?.price || 0).toFixed(2)} €
                      </TableCell>
                    </TableRow>
                  );
                })}
                {/* <TableRow>
                  <TableCell>Acconto Viaggio</TableCell>
                  <TableCell>10%</TableCell>
                </TableRow> */}

                <TableRow>
                  <TableCell>
                    <Typography variant="h6">Totale</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">
                      {Number(totalPrice).toFixed(2)} €
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {customData?.trip?.installments?.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom component="div">
              Modalità di Pagamento
            </Typography>
            <Divider sx={{ mb: 2 }} />
          </Grid>
        )}

        <Grid item xs={12}>
          {(customData?.trip?.installments || []).map((installment, index) => {
            return (
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                p={3}
              >
                <Typography variant="h6">{index + 1}° Rata</Typography>
                <Typography variant="h6">{installment.name}</Typography>
                <Typography variant="h6">
                  {Number(installment?.price || 0).toFixed(2)} €
                </Typography>
                {installment.type == "days" && (
                  <Typography variant="h6">
                    {installment.days} giorni prima della partenza
                  </Typography>
                )}
                {installment.type == "full" && (
                  <Typography variant="h6">Pagamento completo</Typography>
                )}
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
}
