import { TextField, Grid } from "@pankod/refine-mui";

export default function CompFields({
  register,
  control,
  errors,
  record,
  getValues,
}) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          {...register("title", { required: "Nome Obbligatorio" })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.title}
          helperText={errors.title?.message}
          required
          fullWidth
          label="Nome"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...register("brief_description", {
            required: "Descrizione Breve Obbligatoria",
          })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.brief_description}
          helperText={errors.title?.brief_description}
          required
          fullWidth
          label="Descrizione Breve"
        />
      </Grid>
    </Grid>
  );
}
