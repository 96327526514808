import { useState } from "react";

import { Input, Tooltip, Badge } from "@pankod/refine-mui";
import { LoadingButton } from "@mui/lab";

import FileUploadIcon from "@mui/icons-material/FileUpload";

import axios from "utils/axios";
import { toast } from "react-toastify";

export default function UploadZone({
  label,
  disabled,
  onChange,
  limit,
  fullWidth,
  defaultValue,
  reset,
}) {
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  const onChangeHandler = async (event) => {
    try {
      setIsUploadLoading(true);

      const formData = new FormData();

      const target = event.target;
      const file = target.files[0];
      const { size } = file;

      formData.append("files", file);

      if (limit && size / 1024 / 1024 > limit) {
        toast.error("File size must be less than " + limit + "MB");
        setIsUploadLoading(false);
        return;
      }

      let res = await axios.post(`/api/upload`, formData, {
        withCredentials: false,
      });

      const payload = {
        ...res.data[0],
        preview: URL.createObjectURL(file),
      };
      if (onChange) onChange(payload);
      if (!reset) setUploaded(true);

      setIsUploadLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Error uploading file");
      setIsUploadLoading(false);
    }
  };

  return (
    <label>
      <Input type="file" sx={{ display: "none" }} onChange={onChangeHandler} />
      <input type="hidden" />
      <Tooltip
        title={defaultValue ? defaultValue.name : ""}
        arrow
        placement="top"
      >
        <Badge badgeContent={defaultValue ? "✔" : null} color="success">
          <LoadingButton
            fullWidth={fullWidth}
            disabled={disabled}
            loading={isUploadLoading}
            loadingPosition="end"
            endIcon={uploaded ? null : <FileUploadIcon />}
            variant="contained"
            component="span"
          >
            {uploaded ? "✔" : label || "Upload"}
          </LoadingButton>
        </Badge>
      </Tooltip>
    </label>
  );
}
