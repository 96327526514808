import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Stack,
  IconButton,
  Button,
  Typography,
  Box,
  CircularProgress,
} from "@pankod/refine-mui";
import axios from "utils/axios";
import { toast } from "react-toastify";
import CustomDataGrid from "../../components/CustomDataGrid";
import { Edit, Delete, Add } from "@mui/icons-material";
import ParticipantEdit from "./ParticipantEdit";
import ParticipantCreate from "./ParticipantCreate";
import ParticipantProgressBar from "./ParticipantProgressBar";
import { useQueryClient } from "@pankod/refine-core";

function ParticipantList({ trip, type }) {
  const [userId, setUserId] = useState();
  const [create, setCreate] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    setUserId(undefined);
  }, []);

  const escort = type === "escort" ?? false;

  const data = trip
    ? trip.participants
        .filter((u) => {
          if (escort && u.escort) return u;
          if (!escort && !u.escort) return u;
          return undefined;
        })
        .map((p) => {
          // console.log(p);
          return {
            id: p.id,
            fullname: `${p.last_name} ${p.first_name}`,
            email: p.email,
            trip: trip.title,
            languages:
              p.languages && p.languages.length
                ? p.languages.map((l) => l.title).join(", ")
                : "",
          };
        })
    : [];

  const handleRemovePartecipant = useCallback(async (id) => {
    try {
      const resp = await axios.post("/api/uninvite-user", {
        user_id: id,
        trip_id: trip.id,
      });
      console.log(resp);

      queryClient.invalidateQueries();
      toast.success(`${escort ? "Accompagnatore" : "Partecipante"} rimosso`);
    } catch (error) {
      toast.error(
        `Errore rimuovendo ${escort ? "Accompagnatore" : "Partecipante"}`
      );
    }
  }, []);

  const columns = useMemo(
    () => [
      { field: "fullname", headerName: "Nome Cognome", flex: 1, minWidth: 200 },
      { field: "email", headerName: "Email", flex: 1, minWidth: 200 },
      { field: "trip", headerName: "Viaggio", flex: 1, minWidth: 200 },
      {
        field: "languages",
        headerName: "Lingue",
        flex: 1,
        minWidth: 200,
        // renderCell: (params) => {
        //   console.log(params.row);
        //   return <span>{params.row}</span>;
        // },
      },
      {
        headerName: "Actions",
        field: "actions",
        minWidth: 50,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <IconButton
                color="primary"
                aria-label="edit participant"
                component="label"
                onClick={() => setUserId(params.id)}
              >
                <Edit />
              </IconButton>
              <IconButton
                color="primary"
                aria-label="delete participant"
                component="label"
                onClick={() => handleRemovePartecipant(params.id)}
              >
                <Delete />
              </IconButton>
            </Stack>
          );
        },
      },
    ],
    []
  );

  if (!trip) return <CircularProgress />;

  const title = (
    <Stack spacing={4} direction="row" alignItems="center">
      <Typography variant="h5">
        {`${escort ? "ACCOMPAGNATORI" : "PARTECIPANTI"}`}
      </Typography>
      <Typography variant="body1">{trip.title}</Typography>
    </Stack>
  );

  return (
    <Stack spacing={2} direction="column">
      {userId ? (
        <ParticipantEdit userId={userId} trip={trip} escort={escort} />
      ) : create ? (
        <ParticipantCreate trip={trip} escort={escort} />
      ) : (
        <>
          <CustomDataGrid title={title} columns={columns} data={data} />
          <div>
            <Button
              variant="contained"
              onClick={() => setCreate(true)}
              disabled={
                data.length >= (escort ? trip.max_escorts : trip.max_students)
              }
            >
              <Add /> Aggiungi
            </Button>
          </div>
        </>
      )}
      <Box mt={20}>
        <br />
        <br />
        <Stack spacing={2} direction="column">
          <Typography variant="body1">
            {`NUMERO ${escort ? "ACCOMPAGNATORI" : "PARTECIPANTI"} (${
              data.length
            }) / POSTI DISPONIBILI (${
              escort ? trip.max_escorts || 0 : trip.max_students || 0
            })`}
          </Typography>
          <ParticipantProgressBar
            count={data.length}
            max={escort ? trip.max_escorts || 0 : trip.max_students || 0}
          />
        </Stack>
      </Box>
    </Stack>
  );
}

export default ParticipantList;
