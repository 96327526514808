import { useState, useEffect } from "react";
import { useSelect } from "@pankod/refine-core";
import { Controller } from "@pankod/refine-react-hook-form";
import { applyRecordToFields } from "utils/utils";
import { styled } from "@mui/material/styles";

import { Stack, TextField, Switch, Typography } from "@pankod/refine-mui";
import MultiSelect from "components/MultiSelect";

const GratuitySwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      color: "#1b90ff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#eeeeee",
        opacity: 1,
        border: "1px solid #8d99ae",
      },
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

export default function ParticipantFields({
  register,
  control,
  errors,
  record,
  getValues,
  setValue,
  trip,
}) {
  // handle insurance policies
  const [insurancePolicies, setInsurancePolicies] = useState([
    {
      name: "",
      type: "mandatory", // mandatory, optional,
      price: "",
      file: null,
    },
  ]);

  const { options: languagesSEL } = useSelect({
    resource: "languages",
    optionLabel: "title",
    optionValue: "id",
  });

  useEffect(() => {
    if (record) {
      applyRecordToFields({ ...record }, setValue);
      // console.log(record);
      if (
        record.insurance_policies &&
        Array.isArray(record.insurance_policies)
      ) {
        const insurancePolicies = record.insurance_policies;
        let policiesMapped = insurancePolicies.map((x) => {
          return {
            label: x.name + " - " + x.price.toFixed(2) + "€",
            id: x.name,
          };
        });
        setValue("insurance_policies", policiesMapped);
      }
    }
    if (trip && trip?.insurance_policies) {
      setInsurancePolicies(
        trip.insurance_policies.map((x) => {
          return {
            label: x.name + " - " + x.price.toFixed(2) + "€",
            value: x.name,
          };
        })
      );
    }
  }, [record]);

  return (
    <Stack autoComplete="off" spacing={2}>
      <Stack spacing={2} direction="row">
        <TextField
          {...register("first_name", { required: "Nome è Obbligatorio" })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.first_name}
          helperText={errors.first_name?.message}
          required
          fullWidth
          // value={record ? record.first_name : ""}
          label="first_name"
        />
        <TextField
          {...register("last_name", { required: "Cognome è Obbligatorio" })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.last_name}
          helperText={errors.last_name?.message}
          required
          fullWidth
          label="last_name"
        />
        <TextField
          {...register("birth_date", {
            required: false,
          })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.birth_date}
          helperText={errors.birth_date?.message}
          // value={record && record.birth_date ? record.birth_date : ""}
          fullWidth
          type="date"
          label="Data Nascita"
        />
      </Stack>

      <Stack spacing={2} direction="row">
        <TextField
          {...register("email", { required: "Email è Obbligatoria" })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.email}
          helperText={errors.email?.message}
          required
          fullWidth
          label="email"
          type={"email"}
        />
        <TextField
          {...register("phone", { required: "Telefono è Obbligatorio" })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.phone}
          helperText={errors.phone?.message}
          required
          fullWidth
          label="phone"
          type={"phone"}
        />
      </Stack>
      <Controller
        control={control}
        name="insurance_policies"
        render={({ field }) => (
          <MultiSelect
            name="Assicurazioni"
            options={insurancePolicies}
            value={field.value}
            onChange={(val) => {
              field.onChange(val);
            }}
          />
        )}
      />
      <Controller
        control={control}
        name="languages"
        render={({ field }) => (
          <MultiSelect
            name="Lingue"
            options={languagesSEL}
            value={field.value}
            onChange={(val) => {
              field.onChange(val);
            }}
          />
        )}
      />
      <Stack spacing={2} direction="row" alignItems="center">
        <Controller
          control={control}
          name="gratuity"
          render={({ field }) => (
            <GratuitySwitch
              name="Gratuity"
              checked={!!field.value || false}
              onChange={(val) => {
                field.onChange(val);
              }}
            />
          )}
        />
        <Typography variant="h6">Usufruisce della gratuità</Typography>
      </Stack>
    </Stack>
  );
}
