import { useMemo } from "react";
import { Stack, IconButton } from "@pankod/refine-mui";
import { Typography, useDataGrid, DataGrid, List } from "@pankod/refine-mui";
import { EditButton, DeleteButton } from "@pankod/refine-mui";

export default function SchoolUserList() {
  const columns = useMemo(
    () => [
      { field: "first_name", headerName: "Nome", flex: 1, minWidth: 200 },
      { field: "last_name", headerName: "Cognome", flex: 1, minWidth: 200 },
      { field: "email", headerName: "Email", flex: 1, minWidth: 200 },
      { field: "phone", headerName: "Telefono", flex: 1, minWidth: 200 },
      // {
      //   headerName: "Actions",
      //   field: "actions",
      //   minWidth: 50,
      //   renderCell: function render(params) {
      //     return (
      //       <Stack direction="row" spacing={1}>
      //         <EditButton hideText recordItemId={params.row.id} />
      //         <DeleteButton hideText recordItemId={params.row.id} />
      //       </Stack>
      //     );
      //   },
      // },
    ],
    []
  );

  const { dataGridProps, sortingMode, sortModel, onSortModelChange } =
    useDataGrid({
      metaData: {
        populate: "*",
      },
    });

  return (
    <List
      resource="trips"
      cardHeaderProps={{
        title: <Typography variant="h5">Studenti</Typography>,
      }}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        sortingMode={sortingMode}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        autoHeight
      />
    </List>
  );
}
