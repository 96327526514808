import React, { useState, useEffect } from "react";

import {
  Box,
  Grid,
  Card,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
} from "@mui/material";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

export default function StepperCmp({
  steps,
  customData,
  onSubmit,
  errorMessage,
}) {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    // check if all required fields are filled
    if (activeStep != steps.length - 1)
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === steps.length - 1) {
      if (isValid) {
        handleSubmit(onSubmit)();
      } else {
        toast.warning(errorMessage || "Please fill all required fields");
        console.log(getValues());
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    reset();
  };

  const {
    handleSubmit,
    reset,
    register,
    control,
    getValues,
    setError,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm();

  return (
    <Grid container sx={{ height: "100%" }}>
      <Grid
        item
        xs={12}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ flex: 1 }}
      >
        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 3 }}>
          {steps.map((step, index) => {
            const stepProps = {};
            const labelProps = {};

            return (
              <Step
                key={index}
                {...stepProps}
                sx={{
                  "& .MuiStepLabel-root .Mui-completed": {
                    color: "success.dark", // circle color (COMPLETED)
                  },
                  "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                    {
                      color: "grey.500", // Just text label (COMPLETED)
                    },
                  "& .MuiStepLabel-root .Mui-active": {
                    color: "success.main", // circle color (ACTIVE)
                  },
                  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                    {
                      color: "primary.main", // Just text label (ACTIVE)
                    },
                  "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                    fill: "black", // circle's number (ACTIVE)
                  },
                }}
              >
                <StepLabel {...labelProps}>{step.label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {steps.map((step, index) => {
          if (index === activeStep)
            return (
              <Card key={index} sx={{ p: 3 }}>
                <Grid container sx={{ minHeight: "100%" }} alignItems="center">
                  <step.component
                    control={control}
                    register={register}
                    errors={errors}
                    getValues={getValues}
                    setValue={setValue}
                    customData={customData}
                    setError={setError}
                    watch={watch}
                  />
                </Grid>
              </Card>
            );
        })}
      </Grid>
      <Grid item xs={12} sx={{ p: 2, height: 150 }}>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleNext} color="inherit">
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
}
