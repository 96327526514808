import { useMemo } from "react";
import { Stack, IconButton } from "@pankod/refine-mui";
import { Typography, useDataGrid, DataGrid, List } from "@pankod/refine-mui";
import { Edit } from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function SchoolTripList() {
  const columns = useMemo(
    () => [
      {
        field: "trip_code",
        headerName: "Codice Viaggio",
        flex: 1,
        minWidth: 100,
      },
      { field: "title", headerName: "Nome Viaggio", flex: 1, minWidth: 200 },
      {
        field: "destination",
        headerName: "Destinazione",
        flex: 1,
        minWidth: 200,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              {params.row?.destination?.title}
            </Stack>
          );
        },
      },
      {
        field: "trip_type",
        headerName: "Tipo Viaggio",
        flex: 1,
        minWidth: 200,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              {params.row?.trip_type?.title}
            </Stack>
          );
        },
      },
      {
        field: "package_type",
        headerName: "Tipo Pacchetto",
        flex: 1,
        minWidth: 200,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              {params.row?.package_type?.title}
            </Stack>
          );
        },
      },
      {
        headerName: "Actions",
        field: "actions",
        minWidth: 50,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <Link to={`/trip-view/${params.row.id}`}>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <Edit />
                </IconButton>
              </Link>
            </Stack>
          );
        },
      },
    ],
    []
  );

  const { dataGridProps, sortingMode, sortModel, onSortModelChange } =
    useDataGrid({
      metaData: {
        populate: "*",
      },
    });

  return (
    <List
      resource="trips"
      cardHeaderProps={{
        title: <Typography variant="h5">Viaggi</Typography>,
      }}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        sortingMode={sortingMode}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        autoHeight
      />
    </List>
  );
}
