import { useState, useEffect } from "react";

import {
  Stack,
  Typography,
  TextField,
  MenuItem,
  Grid,
  Card,
  Button,
  Autocomplete,
  useAutocomplete,
  Divider,
} from "@pankod/refine-mui";
import { useSelect } from "@pankod/refine-core";

import { Controller } from "@pankod/refine-react-hook-form";
import MultiSelect from "components/MultiSelect";
import SimpleUploadZone from "components/SimpleUploadZone";

import { updateFieldsWithRecordValues } from "utils/utils";

import UploadZone from "components/UploadZone";
import axios from "utils/axios";
import { toast } from "react-toastify";

export default function CompFields({
  register,
  control,
  errors,
  record,
  getValues,
  setError,
  setValue,
}) {
  const { autocompleteProps: schoolAC } = useAutocomplete({
    resource: "schools",
  });
  const { autocompleteProps: packageTypeAC } = useAutocomplete({
    resource: "package-types",
  });
  const { autocompleteProps: tripTypeAC } = useAutocomplete({
    resource: "trip-types",
  });
  const { autocompleteProps: destinationAC } = useAutocomplete({
    resource: "destinations",
  });
  const { options: airportsAC } = useSelect({
    resource: "departure-airports",
    optionLabel: "title",
    optionValue: "id",
  });
  const { options: accommodationsAC } = useSelect({
    resource: "accommodations",
    optionLabel: "title",
    optionValue: "id",
  });
  const { autocompleteProps: treatmentsAC } = useAutocomplete({
    resource: "treatments",
  });
  const { autocompleteProps: agentAC } = useAutocomplete({
    resource: "agents",
  });
  const { options: activitiesAC } = useSelect({
    resource: "activities",
    optionLabel: "title",
    optionValue: "id",
  });
  const { autocompleteProps: scheduleAC } = useAutocomplete({
    resource: "schedules",
  });

  // handle payment installments
  const [paymentInstallments, setPaymentInstallments] = useState([
    {
      name: "",
      price: "",
      days: 0,
      type: "full", //days, full
    },
  ]);
  // handle insurance policies
  const [insurancePolicies, setInsurancePolicies] = useState([
    {
      name: "",
      type: "mandatory", // mandatory, optional,
      price: "",
      file: null,
    },
  ]);
  // handle non school and school packages
  const [selectedPackageType, setSelectedPackageType] = useState(null);

  // fill up payments and insurances if there's any
  useEffect(() => {
    // if (record) console.log(record);
    if (record?.installments) {
      setPaymentInstallments(record.installments);
    }
    if (record?.insurance_policies) {
      setInsurancePolicies(record.insurance_policies);
    }
    // populate all fields from the record
    if (record) {
      updateFieldsWithRecordValues(record, setValue);
      setSelectedPackageType(record.package_type);
    }
  }, [record]);

  return (
    <Grid container spacing={2} sx={{ maxWidth: "90vw" }}>
      <Grid item xs={12} sx={{ mb: 3 }}>
        <UploadZone
          {...register("image", {
            required: false,
          })}
          defaultValue={record?.image}
          required
          fullWidth
          label="Immagine"
          onChange={async (file) => {
            await axios.put("/api/activities/" + record.id, {
              data: {
                image: file,
              },
            });
            toast.success("Immagine caricata con successo");
          }}
        />
      </Grid>
      {/* Trip details */}
      <Grid item xs={12}>
        <Card sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <TextField
                {...register("title", {
                  required: "Nome Viaggio Obbligatorio",
                })}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors?.title}
                helperText={errors.title?.message}
                required
                fullWidth
                label="Nome Viaggio"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                {...register("trip_code", {
                  required: "Codice Viaggio Obbligatorio",
                })}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors?.trip_code}
                helperText={errors.trip_code?.message}
                required
                fullWidth
                label="Codice Viaggio"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                name="package_type"
                rules={{ required: "Tipo Pacchetto Obbligatorio" }}
                render={({ field }) => (
                  <Autocomplete
                    {...packageTypeAC}
                    {...field}
                    value={
                      field.value
                        ? field.value
                        : record
                        ? record.package_type
                        : null
                    }
                    onChange={(_, value) => {
                      setSelectedPackageType(value);
                      setValue("min_attendee", 0);
                      setValue("max_attendee", 0);
                      setValue("school_name", "");
                      setValue("max_students", 0);
                      setValue("max_escorts", 0);
                      setValue("num_gratuities", 0);
                      field.onChange(value);
                      // console.log(value);
                      // if (value?.id === 1) {
                      //   console.log("non scolastico", getValues());
                      // } else {
                      //   console.log("scolastico", getValues());
                      // }
                    }}
                    getOptionLabel={(item) => {
                      return item.title ? item.title : "";
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return value === undefined || option.id === value.id;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipo Pacchetto"
                        variant="outlined"
                        error={!!errors.package_type}
                        helperText={errors.package_type?.message}
                        required
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display:
                  selectedPackageType?.title == "Viaggio Scolastico"
                    ? "block"
                    : "none",
              }}
            >
              <Controller
                control={control}
                name="trip_type"
                rules={{ required: false }}
                render={({ field }) => (
                  <Autocomplete
                    {...tripTypeAC}
                    {...field}
                    value={
                      field.value
                        ? field.value
                        : record
                        ? record.trip_type
                        : null
                    }
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    getOptionLabel={(item) => {
                      return item.title ? item.title : "";
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return value === undefined || option.id === value.id;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipo Viaggio"
                        variant="outlined"
                        error={!!errors.trip_type}
                        helperText={errors.trip_type?.message}
                        required={false}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                {...register("price", { required: "Prezzo Obbligatorio" })}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors?.price}
                helperText={errors.price?.message}
                required
                fullWidth
                type="number"
                label="price"
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}></Grid>
            <Grid item xs={12} md={4}>
              <TextField
                {...register("min_attendee", {
                  // required:
                  //   selectedPackageType?.title == "Viaggio Non Scolastico"
                  //     ? "Minimo Partecipanti Obbligatorio"
                  //     : false,
                })}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors?.min_attendee}
                helperText={errors.min_attendee?.message}
                fullWidth
                label="Minimo Partecipanti"
                type="number"
                sx={{
                  display:
                    selectedPackageType?.title == "Viaggio Non Scolastico"
                      ? "block"
                      : "none",
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                {...register("max_attendee", {
                  // required:
                  // selectedPackageType?.title == "Viaggio Non Scolastico"
                  //   ? "Massimo Partecipanti Obbligatorio"
                  //   : false,
                })}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors?.max_attendee}
                helperText={errors.max_attendee?.message}
                fullWidth
                label="Massimo Partecipanti"
                type="number"
                sx={{
                  display:
                    selectedPackageType?.title == "Viaggio Non Scolastico"
                      ? "block"
                      : "none",
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                {...register("private", {})}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors?.max_attendee}
                helperText={errors.max_attendee?.message}
                fullWidth
                label="Viaggio Privato"
                defaultValue={record ? record.private : false}
                select
                sx={{
                  display:
                    selectedPackageType?.title == "Viaggio Non Scolastico"
                      ? "block"
                      : "none",
                }}
              >
                <MenuItem value={true}>Si</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </TextField>
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display:
                  selectedPackageType?.title == "Viaggio Scolastico"
                    ? "block"
                    : "none",
              }}
            >
              <Controller
                control={control}
                name="school"
                render={({ field }) => (
                  <Autocomplete
                    {...schoolAC}
                    {...field}
                    value={
                      field.value ? field.value : record ? record.school : null
                    }
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    getOptionLabel={(item) => {
                      return item.username ? item.username : "";
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return value === undefined || option.id === value.id;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Scuola"
                        variant="outlined"
                        error={!!errors.school}
                        helperText={errors.school?.message}
                        required
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                {...register("max_students", {
                  // required: "Viaggio Scolastico"
                  //   ? "Numero Studenti Obbligatorio"
                  //   : false,
                })}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors?.max_students}
                helperText={errors.max_students?.message}
                fullWidth
                label="Numero Studenti"
                type="number"
                sx={{
                  display:
                    selectedPackageType?.title == "Viaggio Scolastico"
                      ? "block"
                      : "none",
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                {...register("max_escorts", {
                  // required: "Viaggio Scolastico"
                  //   ? "Numero Accompagnatori Obbligatorio"
                  //   : false,
                })}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors?.max_escorts}
                helperText={errors.max_escorts?.message}
                fullWidth
                label="Numero Accompagnatori"
                type="number"
                sx={{
                  display:
                    selectedPackageType?.title == "Viaggio Scolastico"
                      ? "block"
                      : "none",
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                {...register("num_gratuities", {
                  // required: "Viaggio Scolastico"
                  //   ? "Numero Gratuità Obbligatorio"
                  //   : false,
                })}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors?.num_gratuities}
                helperText={errors.num_gratuities?.message}
                fullWidth
                label="Numero Gratuità"
                type="number"
                sx={{
                  display:
                    selectedPackageType?.title == "Viaggio Scolastico"
                      ? "block"
                      : "none",
                }}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      {/* Payments */}
      <Grid item xs={12}>
        <Card sx={{ p: 2 }}>
          <Grid container rowSpacing={2}>
            {paymentInstallments.map((item, index) => {
              return (
                <Card sx={{ p: 2, my: 2, width: "100%" }} key={index}>
                  <Grid container spacing={2} item>
                    <Grid item xs={12}>
                      <Button
                        color="error"
                        onClick={() => {
                          const newInstallments = [...paymentInstallments];
                          newInstallments.splice(index, 1);
                          setPaymentInstallments(newInstallments);
                          setValue("installments", newInstallments);
                        }}
                      >
                        Elimina Quota
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={item.type == "days" ? 3 : 4}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        label="Nome Quota"
                        value={item.name}
                        onChange={(e) => {
                          const newInstallments = [...paymentInstallments];
                          newInstallments[index].name = e.target.value;
                          setPaymentInstallments(newInstallments);
                          setValue("installments", newInstallments);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={item.type == "days" ? 3 : 4}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        label="Prezzo"
                        type="number"
                        value={item.price}
                        onChange={(e) => {
                          const newInstallments = [...paymentInstallments];
                          newInstallments[index].price = e.target.value;
                          setPaymentInstallments(newInstallments);
                          setValue("installments", newInstallments);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={item.type == "days" ? 3 : 4}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        label="Tipo Pagamento"
                        value={item.type}
                        select
                        onChange={(e) => {
                          const newInstallments = [...paymentInstallments];
                          newInstallments[index].type = e.target.value;
                          setPaymentInstallments(newInstallments);
                          setValue("installments", newInstallments);
                        }}
                      >
                        <MenuItem value="days">Giorni</MenuItem>
                        <MenuItem value="full">Saldo</MenuItem>
                      </TextField>
                    </Grid>
                    {item.type == "days" && (
                      <Grid item xs={12} md={item.type == "days" ? 3 : 4}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          label="Giorni"
                          value={item.days}
                          type="number"
                          onChange={(e) => {
                            const newInstallments = [...paymentInstallments];
                            newInstallments[index].days = e.target.value;
                            setPaymentInstallments(newInstallments);
                            setValue("installments", newInstallments);
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Card>
              );
            })}
            <Button
              onClick={() =>
                setPaymentInstallments([...paymentInstallments, {}])
              }
              sx={{ mt: 3 }}
            >
              Aggiungi Quota
            </Button>
          </Grid>
        </Card>
      </Grid>
      {/* Insurance policies */}
      <Grid item xs={12}>
        <Card sx={{ p: 2 }}>
          <Grid container rowSpacing={2}>
            {insurancePolicies.map((item, index) => {
              return (
                <Card sx={{ p: 2, my: 2, width: "100%" }} key={index}>
                  <Grid container spacing={2} item alignItems="center">
                    <Grid item xs={12}>
                      <Button
                        color="error"
                        onClick={() => {
                          const newPolicies = [...insurancePolicies];
                          newPolicies.splice(index, 1);
                          setInsurancePolicies(newPolicies);
                          setValue("insurance_policies", newPolicies);
                        }}
                      >
                        Elimina Polizza
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        label="Nome Polizza"
                        value={item.name}
                        onChange={(e) => {
                          const newPolicies = [...insurancePolicies];
                          newPolicies[index].name = e.target.value;
                          setInsurancePolicies(newPolicies);
                          setValue("insurance_policies", newPolicies);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        label="Tipo Polizza"
                        value={item.type}
                        select
                        onChange={(e) => {
                          const newPolicies = [...insurancePolicies];
                          newPolicies[index].type = e.target.value;
                          setInsurancePolicies(newPolicies);
                          setValue("insurance_policies", newPolicies);
                        }}
                      >
                        <MenuItem value="mandatory">Obbligatoria</MenuItem>
                        <MenuItem value="optional">Opzionale</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        label="Prezzo"
                        type="number"
                        value={item.price}
                        onChange={(e) => {
                          const newPolicies = [...insurancePolicies];
                          newPolicies[index].price = e.target.value;
                          setInsurancePolicies(newPolicies);
                          setValue("insurance_policies", newPolicies);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} container justifyContent="center">
                      <SimpleUploadZone
                        label="Carica Informativa"
                        defaultValue={item.informations}
                        onChange={(file) => {
                          const newPolicies = [...insurancePolicies];
                          newPolicies[index].informations = file;
                          setInsurancePolicies(newPolicies);
                          setValue("insurance_policies", newPolicies);
                          toast.success("Informativa caricata con successo");
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} container justifyContent="center">
                      <SimpleUploadZone
                        label="Carica Polizza"
                        defaultValue={item.file}
                        onChange={(file) => {
                          const newPolicies = [...insurancePolicies];
                          newPolicies[index].file = file;
                          setInsurancePolicies(newPolicies);
                          setValue("insurance_policies", newPolicies);
                          toast.success("Polizza caricata con successo");
                        }}
                      />
                    </Grid>
                  </Grid>
                </Card>
              );
            })}
            <Button
              onClick={() => setInsurancePolicies([...insurancePolicies, {}])}
              sx={{ mt: 3 }}
            >
              Aggiungi Polizza
            </Button>
          </Grid>
        </Card>
      </Grid>
      {/* Annullamento e destinazione */}
      <Grid item xs={12} sx={{ my: 2 }}>
        <Divider sx={{ my: 1 }} />
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="space-evenly">
          <Grid item xs={12} md={4}>
            <TextField
              {...register("cancellation", {
                required: "Data Annullamento Obbligatoria",
              })}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors?.cancellation}
              helperText={errors.cancellation?.message}
              required
              fullWidth
              type="date"
              label="Data Annullamento"
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Controller
              control={control}
              name="destination"
              rules={{ required: "Destinazione Obbligatoria" }}
              render={({ field }) => (
                <Autocomplete
                  {...destinationAC}
                  {...field}
                  value={
                    field.value
                      ? field.value
                      : record
                      ? record.destination
                      : null
                  }
                  onChange={(_, value) => {
                    field.onChange(value);
                  }}
                  getOptionLabel={(item) => {
                    return item.title ? item.title : "";
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return value === undefined || option.id === value.id;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Destinazione"
                      variant="outlined"
                      error={!!errors.destination}
                      helperText={errors.destination?.message}
                      required
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ my: 2 }}>
        <TextField
          {...register("description", {
            required: "Descrizione Obbligatoria",
          })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.description}
          helperText={errors.description?.message}
          required
          fullWidth
          label="Descrizione"
          multiline
          rows={7}
        />
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            {...register("age_range", {
              required: "Fascia di Età Obbligatoria",
            })}
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errors?.age_range}
            helperText={errors.age_range?.message}
            required
            fullWidth
            label="Fascia di Età"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            {...register("trip_duration_days", {
              required: "Durata del Viaggio Obbligatoria",
            })}
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errors?.trip_duration_days}
            helperText={errors.trip_duration_days?.message}
            required
            fullWidth
            type="number"
            label="Durata del Viaggio in Giorni"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            {...register("departure_date", {
              required: "Data di Partenza Obbligatoria",
            })}
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errors?.departure_date}
            helperText={errors.departure_date?.message}
            required
            fullWidth
            type="date"
            label="Data di Partenza"
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...register("transfer", {
            required: "Trasferimenti Obbligatori",
          })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.transfer}
          helperText={errors.transfer?.message}
          required
          fullWidth
          label="Trasferimenti"
          multiline
          rows={5}
        />
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            name="departure_airports"
            render={({ field }) => (
              <MultiSelect
                name="Aeroporti di Partenza"
                options={airportsAC}
                value={field.value}
                onChange={(val) => {
                  field.onChange(val);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            name="accommodations"
            render={({ field }) => (
              <MultiSelect
                name="Sistemazioni"
                options={accommodationsAC}
                value={field.value}
                onChange={(val) => {
                  field.onChange(val);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            name="treatment"
            rules={{ required: "Trattamento Obbligatorio" }}
            render={({ field }) => (
              <Autocomplete
                {...treatmentsAC}
                {...field}
                value={
                  field.value ? field.value : record ? record.treatment : ""
                }
                onChange={(_, value) => {
                  field.onChange(value);
                }}
                getOptionLabel={(item) => {
                  return item.title ? item.title : "";
                }}
                isOptionEqualToValue={(option, value) => {
                  return value === undefined || option.id === value.id;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Trattamento"
                    variant="outlined"
                    error={!!errors.treatment}
                    helperText={errors.treatment?.message}
                    required
                  />
                )}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ my: 2 }}>
        <TextField
          {...register("course_of_study", {
            required: "Corso di Studi Obbligatorio",
          })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.course_of_study}
          helperText={errors.course_of_study?.message}
          required
          fullWidth
          label="Corso di Studi"
          multiline
          rows={5}
        />
      </Grid>
      <Grid item xs={12} sx={{ my: 2 }}>
        <Controller
          control={control}
          name="activities"
          render={({ field }) => (
            <MultiSelect
              name="Attività"
              options={activitiesAC}
              value={field.value}
              onChange={(val) => {
                field.onChange(val);
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sx={{ my: 2 }}>
        <TextField
          {...register("excursions_and_tours", {
            required: "Escursioni e Visite Obbligatori",
          })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.excursions_and_tours}
          helperText={errors.excursions_and_tours?.message}
          required
          fullWidth
          label="Escursioni e Visite"
          multiline
          rows={5}
        />
      </Grid>
      <Grid item xs={12} sx={{ my: 2 }}>
        <TextField
          {...register("support_and_supervision", {
            required: "Supporto e Supervisione Obbligatori",
          })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.support_and_supervision}
          helperText={errors.support_and_supervision?.message}
          required
          fullWidth
          label="Supporto e Supervisione"
          multiline
          rows={5}
        />
      </Grid>
      <Grid item xs={12} sx={{ my: 2 }}>
        <TextField
          {...register("fee_includes", {
            required: "Quote Includono Obbligatori",
          })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.fee_includes}
          helperText={errors.fee_includes?.message}
          required
          fullWidth
          label="Quote Includono"
          multiline
          rows={5}
        />
      </Grid>
      <Grid item xs={12} sx={{ my: 2 }}>
        <TextField
          {...register("fee_not_includes", {
            required: "Quote Non Includono Obbligatori",
          })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.fee_not_includes}
          helperText={errors.fee_not_includes?.message}
          required
          fullWidth
          label="Quote NON Includono"
          multiline
          rows={5}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Controller
          control={control}
          name="agent"
          rules={{ required: "Agente Obbligatorio" }}
          render={({ field }) => {
            return (
              <Autocomplete
                {...agentAC}
                {...field}
                value={field.value ? field.value : record ? record.agent : ""}
                onChange={(_, value) => {
                  field.onChange(value);
                }}
                getOptionLabel={(item) => {
                  return item.first_name ? item.first_name : "";
                }}
                isOptionEqualToValue={(option, value) => {
                  return value === undefined || option.id === value.id;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Agente"
                    variant="outlined"
                    error={!!errors.agent}
                    helperText={errors.agent?.message}
                    required
                  />
                )}
              />
            );
          }}
        />
      </Grid>

      <Grid item xs={12} sx={{ mt: 1 }}>
        <Typography variant="h6" sx={{ my: 1 }}>
          Programma del Viaggio
        </Typography>
        <Controller
          control={control}
          name="schedule"
          rules={{ required: "Programma del Viaggio Obbligatoria" }}
          render={({ field }) => (
            <Autocomplete
              {...scheduleAC}
              {...field}
              value={
                field.value ? field.value : record ? record.schedule : null
              }
              onChange={(_, value) => {
                field.onChange(value);
              }}
              getOptionLabel={(item) => {
                return item.title ? item.title : "";
              }}
              isOptionEqualToValue={(option, value) => {
                return value === undefined || option.id === value.id;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  error={!!errors.schedule}
                  helperText={errors.schedule?.message}
                  required
                />
              )}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
