import { useMemo, useState, useEffect } from "react";
import {
  Grid,
  MenuItem,
  Stack,
  Button,
  Dialog,
  Card,
  Typography,
  useDataGrid,
  DataGrid,
  List,
  TextField,
} from "@pankod/refine-mui";

import SimpleUploadZone from "components/SimpleUploadZone";

import { API_URL } from "constants";
import FileViewer from "components/FileViewer";
import { truncateString } from "utils/utils";
import axios from "utils/axios";
import { toast } from "react-toastify";
import { normalizeData } from "utils/utils";

export default function CompList() {
  const [user, setUser] = useState(null);
  const [description, setDescription] = useState("");
  const [trip, setTrip] = useState(null);
  const [trips, setTrips] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [rejecting, setRejecting] = useState(false);
  const [rejectingNote, setRejectingNote] = useState("");

  const getMe = async () => {
    const { data } = await axios.get("/api/users/me");
    console.log(data);
    setUser(data);
  };

  const getUsers = async () => {
    const { data } = await axios.get("/api/users");
    setUsers(normalizeData(data));
  };

  const getTrips = async () => {
    const { data } = await axios.get("/api/trips");
    setTrips(normalizeData(data));
  };

  useEffect(() => {
    if (!user) getMe();
    if (user && user.role.name == "bl_amministrazione") {
      getUsers();
      getTrips();
    }
  }, [user]);

  const columns = useMemo(
    () => [
      {
        field: "user",
        headerName: "Utente",
        flex: 1,
        minWidth: 100,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              {params.row?.uploaderName}
            </Stack>
          );
        },
      },
      {
        field: "trip",
        headerName: "Viaggio",
        flex: 1,
        minWidth: 100,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              {params.row?.trip?.title}
            </Stack>
          );
        },
      },
      { field: "description", headerName: "Tipo", flex: 1, minWidth: 200 },
      {
        field: "attachment",
        headerName: "Documento",
        flex: 1,
        minWidth: 250,
        renderCell: function render(params) {
          return (
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="body2">
                  {truncateString(params.row?.attachment?.name || "N/A", 15)}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    console.log(params);
                    setSelectedDocument({
                      ...params.row.attachment,
                      id: params.row.id,
                    });
                  }}
                >
                  Apri
                </Button>
              </Grid>
            </Grid>
          );
        },
      },
      {
        field: "status",
        headerName: "Stato",
        flex: 1,
        minWidth: 150,
        renderCell: function render(params) {
          return (
            <Stack
              direction="row"
              spacing={1}
              sx={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor:
                  params.row.status == "submitted"
                    ? "warning.main"
                    : params.row.status == "accepted"
                    ? "success.main"
                    : "error.main",
              }}
            >
              {params.row.status == "submitted"
                ? "In Attesa"
                : params.row.status == "accepted"
                ? "Accettato"
                : "Rifiutato"}
            </Stack>
          );
        },
      },
      {
        field: "note",
        headerName: "Note",
        flex: 1,
        minWidth: 200,
        renderCell: function render(params) {
          return (
            <Stack
              direction="row"
              spacing={1}
              onClick={() => {
                alert(params.row.note);
              }}
              sx={{
                cursor: "pointer",
              }}
            >
              {truncateString(params.row.note || "", 20)}
            </Stack>
          );
        },
      },
      // {
      //   headerName: "Actions",
      //   field: "actions",
      //   minWidth: 50,
      //   renderCell: function render(params) {
      //     return (
      //       <Stack direction="row" spacing={1}>
      //         <EditButton hideText recordItemId={params.row.id} />
      //         <DeleteButton hideText recordItemId={params.row.id} />
      //       </Stack>
      //     );
      //   },
      // },
    ],
    []
  );

  const { dataGridProps, sortingMode, sortModel, onSortModelChange } =
    useDataGrid({
      metaData: {
        populate: "*",
      },
    });

  return (
    <List
      resource="user-attachments"
      createButtonProps={{ size: "small" }}
      headerProps={{
        title: <Typography variant="h5">Documenti</Typography>,
      }}
      canCreate={false}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        sortingMode={sortingMode}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        autoHeight
        liveMode="auto"
      />

      {user && user.role.name == "bl_amministrazione" && (
        <Grid
          container
          justifyContent="space-around"
          alignItems="center"
          sx={{ mt: 2 }}
          spacing={2}
        >
          <Grid item xs={12} md={4}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
              label="Descrizione Documento"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
              label="Viaggio"
              select
              value={trip || ""}
              onChange={(e) => {
                setTrip(e.target.value);
              }}
            >
              {trips?.map((option, index) => (
                <MenuItem key={index} value={option.id}>
                  {option.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} md={3}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
              label="Utente"
              select
              value={selectedUser || ""}
              onChange={(e) => {
                setSelectedUser(e.target.value);
              }}
            >
              {users.map((option, index) => (
                <MenuItem key={index} value={option.id}>
                  {option.username}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} md={2} container justifyContent="center">
            <SimpleUploadZone
              disabled={!user || !trip || description.length < 1}
              onChange={async (file) => {
                console.log(selectedUser, file, trip);
                try {
                  await axios.post("/api/user-attachments", {
                    data: {
                      user: selectedUser,
                      uploaderName: user.username,
                      attachment: file.id,
                      description: description,
                      trip: trip,
                      status: "accepted",
                    },
                  });
                  toast.success("Documento caricato con successo");
                  await new Promise((resolve) => setTimeout(resolve, 1000));
                  window.location.reload();
                } catch (error) {
                  toast.error("Errore durante il caricamento del documento");
                  await new Promise((resolve) => setTimeout(resolve, 1000));
                  // window.location.reload();
                }
              }}
            />
          </Grid>
        </Grid>
      )}

      <Dialog
        open={!!selectedDocument}
        onClose={() => {
          setSelectedDocument(null);
        }}
        fullScreen
      >
        <Card sx={{ overflowY: "auto", width: "100%", height: "100%" }}>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            sx={{ p: 2 }}
            spacing={3}
          >
            <Grid item>
              <Button
                variant="contained"
                color="success"
                onClick={async () => {
                  axios
                    .put("/api/user-attachments/" + selectedDocument.id, {
                      data: {
                        status: "accepted",
                      },
                    })
                    .then(async () => {
                      toast.success("Documento Accettato");
                      await new Promise((resolve) => setTimeout(resolve, 1000));
                      window.location.reload();
                    })
                    .catch(() => {
                      toast.error(
                        "Errore durante l'accettazione del documento"
                      );
                    });

                  setSelectedDocument(null);
                }}
              >
                Accetta
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  setRejecting(true);
                }}
              >
                Rifiuta
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setSelectedDocument(null);
                }}
              >
                Chiudi
              </Button>
            </Grid>
          </Grid>
          {selectedDocument && (
            <Grid
              sx={{ width: "100%", height: "90%" }}
              container
              justifyContent="center"
              alignItems="center"
            >
              <FileViewer
                file={`${API_URL}${selectedDocument.url}`}
                type={selectedDocument.ext.replace(".", "")}
              />
            </Grid>
          )}
        </Card>
        <Dialog
          open={rejecting}
          onClose={() => {
            setRejecting(false);
          }}
        >
          <Card>
            <Grid container sx={{ minWidth: 500 }}>
              <Grid item xs={12} sx={{ p: 3 }}>
                <TextField
                  fullWidth
                  label="Note"
                  value={rejectingNote}
                  onChange={(e) => {
                    setRejectingNote(e.target.value);
                  }}
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12} sx={{ p: 3 }}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={async () => {
                    axios
                      .put("/api/user-attachments/" + selectedDocument.id, {
                        data: {
                          status: "rejected",
                          note: rejectingNote,
                        },
                      })
                      .then(async () => {
                        toast.success("Documento Rifiutato");
                        await new Promise((resolve) =>
                          setTimeout(resolve, 1000)
                        );
                        window.location.reload();
                      })
                      .catch(() => {
                        toast.error("Errore durante il rifiuto del documento");
                      });
                  }}
                >
                  Invia
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Dialog>
      </Dialog>
    </List>
  );
}
